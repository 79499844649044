.channelsBlock {
    width: 100%;
    min-width: 430px;

    .buttonContainer {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .creationBlock {
        padding: 20px;
        margin-top: 30px;
        border-radius: 20px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);

        h4 {
            margin-top: 0;
        }

        .inputContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .inputBlock {
                display: flex;
                gap: 10px;
            }

            .btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .imgContainer {
        display: flex;
        gap: 5px;
    }

    .tokens {
        padding: 25px 0 5px 25px;
        font-size: 16px;
        font-weight: 700;
    }

    .editModal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vw;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10;

        & > div {
            position: absolute;
            top: 90px;
            right: 30px;
            width: 400px;
            height: 80vh;
            background-color: #F9FAFC;
            border-radius: 7px;
            overflow-y: scroll;
            scrollbar-width: none ;
            font-size: 12px;
        
            &::-webkit-scrollbar {
                display: none;
            }
    
        }

        .editHeader {
            position: relative;
            background-color: #FFF;
            padding: 15px 10px;
            border-bottom: 1px solid #CFCFCF;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;

            .iconContainer {
                position: absolute;
                top: 15px;
                right: 15px;
                display: flex;
                gap: 10px;
                cursor: pointer;
            }
        }
    }
}