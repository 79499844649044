@import 'src/vars';

.container {
  position: relative;
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.withoutlable {
  margin-top: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // right: 0;
  // bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  top: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $deafault-blue;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $deafault-blue;
}

input:checked + .slider:before {
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.nameLabel {
  font-weight: 300;
  font-size: 14px;
  line-height: 16.8px;
}

.lable {
  position: absolute;
  top: -30px;
  left: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #000000CC;

}
