.wrapperInput {
  margin-top: 30px;
  width: 100%;
  position: relative;
}

.withoutlable {
  margin-top: 0;
}

.customInput{
  width: inherit;
  border-radius: 4px;
  padding: 10px 16px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #CFCFCF;
  font-size: 12px;
  line-height: 19.2px;
  background-color: white;
}


.customInput::placeholder {
  font-size: 12px;
  line-height: 19.2px;
  color: #00000066;
}

.customInput:disabled {
  background-color: rgba(218, 206, 206, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input_eye-img{
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.error {
  border: 1px solid rgba(256, 0, 0, 0.8);
}

.lable {
  position: absolute;
  top: -30px;
  left: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #000000CC;

}

.iconContainer {
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  max-height: 40px;
  display: flex;
  align-items: center;

  svg {
    // height: 15px;
  }
}

.iconStyle {
  padding-left: 30px;
}

.errorMessage {
  color: rgba(256, 0, 0, 0.8);
}
