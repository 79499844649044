$default-black: #000;
$default-white: #fff;
$accent-color: #006AFF;

$deafault-blue: #1718FF;
$gray: #f5f6fa;
$font-gray: #000000CC;
$light-blue: #F6FAFF;

$gray-ED: #EDEDED;
$gray-F7: #F7F8F9;
$gray-EE: #EEEEF2;
$gray-95: #959595;
$gray-FB: #FBFBFB;
$sidebar-grey: #2F2F2F;
$gray-C8: #C8C8C866;

$bg-dark-grey: #E6E6F0;
$bg-light-grey: #F7F8F9;
$bg-light-blue: #E7F6FE;
$bg-bright-blue: #5DBBF4;

$main-text-color: #000;
$black-f2: #2F2F2F;


$green-highlights-text: #05E200;
$green-1E: #1EFD45;
$red-highlights-text: #E32121;
$red-FF: #FF2F1F;
$pink-highlights-text: #E66287;
$blue-light-highlights-text: #5DBBF4;
$blue-highlights-text: #5DBBF4;

$pink-FD: #FD1EBA;
$purple-97: #9747FF;
$blue-00: #006AFF;
$blue-58: #59A9F2;
$blue-6E: #6EC2F5;
$blue-F4: #F4FBFF;