.channelsBlock {
    width: 100%;
    min-width: 430px;

    .title {
        font-size: 24px;
        font-weight: 500;
    }

    .creationBlock {
        border-radius: 20px;

        h4 {
            margin-top: 0;
            margin-bottom: 15px;
        }

        .inputContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .inputBlock {
                display: flex;
                gap: 10px;
            }

            .btn {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}