@import 'src/vars';

.creationBlock, .fileBlock {

    .inputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        color: $font-gray;
        margin-bottom: 15px;
    }

    .info {
        margin-top: -10px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #8B8B8B;
    }
}

.fileBlock{
    margin-top: 20px;
    margin-bottom: 30px;
    
    .inputContainer {
        flex-direction: row;
        justify-content: space-between;
    }
}
