@import "src/vars";

.stepsContainer {
    display: flex;
    justify-content: start;
    gap: 15px;
    border-bottom: 2px solid #BFBFBFCC;

    .step {
        padding-bottom: 5px;
        margin-bottom: -1px;
        font-size: 14px;
        font-weight: 500;
        color: $font-gray;
        cursor: pointer;
    }

    .active {
        color: $deafault-blue;
        border-bottom: 2px solid $deafault-blue;
    }
}