@import 'src/vars';

.body {
    padding: 5px 10px 10px 10px;

    .steps {
        margin: 5px 0 20px 0;
    }

    h3 {
        margin-bottom: 0;
    }

    .inputBlock {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .settingButtons {
        margin-bottom: 25px;
        display: flex;
        gap: 15px;
    }

    .rightBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: $font-gray;
    }

    .titleIntervals {
        font-size: 16px;
        font-weight: 500;
        color: $font-gray;
        margin-top: 15px;
    }

    .timeBlock {
        display: flex;
        gap: 15px;

        .timeItem {
            display: flex;
            flex-wrap: nowrap;
            gap: 10px;
            align-items: center;
            margin-top: 5px;

            .inputTime {
                width: 70px;
            }
        }
    }

    .intervalsBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 200px;

        .interval {
            display: flex;
            gap: 10px;
        }

        .deleteBtn {
            background-color: rgb(210, 36, 36);
            border: 1px solid rgb(210, 36, 36);
        }
    }

    .saveBtnContainer {
        position: fixed;
        top: calc(80vh + 65px + 15px);
        left: 250px;
    }
}