@import "src/vars";

.body {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.wrapperRegister {
  width: 50vw;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.background {
  position: relative;
  width: 50vw;

  .backImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    p {
      font-size: 24px;
      color: #FFF;
      text-align: center;
    }
    
  }
}

.formRegister{
  width: 45%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.title{
  font-size: 24px;
  line-height: 29px;
}

.underText {
  display: flex;
  gap: 3px;
  width: 100%;
  justify-content: center;
}

.oneText, .twoText {
  font-size: 16px;
  line-height: 19px;
}

.twoText {
  color: $deafault-blue;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.leftText, .rightText{
  font-size: 16px;
  line-height: 19px;
}

.leftText{
  color: $red-highlights-text;
  cursor: pointer;
}
  
.rightText{
  color: $blue-58;
  cursor: pointer;
}

.formGroup{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.resendEmail {
  padding-top: 20px;
  font-size: 12px;
  font-weight: 300;
  text-align: justify;
}

.authBtn {
    width: 100%;
}

.linkStyle{
  color: #59AAF3;
  text-decoration: underline;
  cursor: pointer;
}

.bottomText {
  padding-bottom: 25px;
  color: #00000099;
  font-size: 12px;
}

@media (max-width: 850px) {
  .body {
    flex-direction: column;
    overflow: visible;

    .wrapperAuth {
      width: 100%;
      padding-top: 20px;
    }

    .background {
      width: 100%;
      max-height: 150px;
    }
  }
}

@media (max-width: 450px) {
  .formRegister {
    width: 100%;
  }
}
