@import 'src/vars';

.layout {
    width: 100vw;
    height: 100vh;
    background-color: $gray;

    .container {
        position: fixed;
        width: calc(100vw - 280px);
        height: 80vh;
        padding: 70px 15px 20px 20px;
        top: 65px;
        left: 250px;
        border-radius: 14px;
        background-color: #FFF;
        z-index: 2;

        .content {
            height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 11px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px #C9C9CB66;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                background-color: #6E6FFF;
            }
        }

        .searchContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 20px;
            display: flex;
            gap: 15px;
            border-radius: 14px;
            background-color: #FFF;

            & > div {
                width: 30%;
            }
        }
    }

    .tabsContainer {
        position: fixed;
        top: 60px;
        left: 250px;
        width: calc(100vw - 280px);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: scroll;
        scrollbar-width: none ;
        z-index: 1;
    
        &::-webkit-scrollbar {
            display: none;
        }

        .tabItem {
            position: relative;
            height: 60px;
            padding: 0 10px 20px 10px;
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 10px;
            color: $font-gray;
            white-space: nowrap;
            background-color: #ebecf0;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            cursor: pointer;

            svg {
                height: 9px;

                &:hover {
                    path {
                        stroke-opacity: 0.5;
                    }
                }
            }

            .fullName {
                display: none;
                position: fixed;
                margin-bottom: 65px;
                margin-left: -10px;
                padding: 5px;
                // bottom: 0;
                // left: 0;
                width: max-content;
                background-color: rgba(176, 173, 173, 0.5);
                border-radius: 5px;
                z-index: 4;
            }

            &:hover {
                .fullName {
                    display: block;
                }
            }
        }

        .activeTab {
            background-color: #FFF;
        }
    }

    .accountContainer {
        position: fixed;
        top: 20px;
        right: 30px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $deafault-blue;
        cursor: pointer;
        z-index: 2;

        .exitButton {
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 12px;
            padding-right: 35px;

            span {
                padding: 5px 8px;
                background-color: #FFF;
                border-radius: 5px;
                cursor: pointer;
    
                &:hover {
                    color: rgb(74, 74, 74);
                }
            }
        }
    }
}