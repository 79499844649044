@import "src/vars";

.buttonContainer {
    position: absolute;
    top: 20px;
    right: 20px;
}

.folders {
    padding-top: 30px;
    display: flex;
    flex-direction: column;

    .folderContainer {
        .folder {
            position: relative;
            padding: 8px 0;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 500;
            border-bottom: 1px solid #E9E9E9;
            color: $font-gray;
            cursor: pointer;

            .addButton {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;

                &:hover {
                    color: #9e9c9c;
                }
            }
        }

        .files {
            padding: 5px 0;
            margin-left: 15px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            border-bottom: 1px solid #E9E9E9;
        }
    }
}

.layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 10;
}

.modalSave {
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #FFF;
    border-radius: 20px;

    span {
        font-size: 24px;
        font-weight: 500;
    }

    .btnContainer {
        display: flex;
        justify-content: space-between;
    }
}

.rotate {
    transform: rotate(-90deg);
}