.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 20;

    .container {
        position: relative;
        max-width: 80vw;
        padding: 20px;
        background-color: #FFF;
        border-radius: 14px;

        .crossIcon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }

        .buttons {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
        }
    }
}