@import 'src/vars';

.body {
    padding: 15px 10px 10px 10px;
    display: flex;
    gap: 30px;
    justify-content: space-between;

    .inputBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h3 {
            font-size: 24px;
            font-weight: 500;
            color: $font-gray;
        }

        .step_2 {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .subTitle {
                padding-top: 5px;
                font-size: 16px;
                font-weight: 500;
            }
        
            .temperature {
                max-width: 350px;
                padding: 15px 20px;
                background-color: #F9FAFC;
                border: 1px solid #CFCFCF;
                border-radius: 4px;
    
                p {
                    margin-bottom: 20px;
                }
            }
        }

        .step_3 {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        textarea {
            height: calc(80vh - 220px) !important;
            resize: none;

            &::-webkit-scrollbar {
              width: 11px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px #C9C9CB66;
              -webkit-border-radius: 10px;
              border-radius: 10px;
              border: 4px solid rgba(0, 0, 0, 0);
              background-clip: padding-box;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              border: 4px solid rgba(0, 0, 0, 0);
              background-clip: padding-box;
              background-color: #6E6FFF;
            }
        }
    }

    .btnContainer {
        position: fixed;
        top: calc(80vh + 65px + 15px);
        left: 250px;
    }

}


.layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 10;
}

.modalSave {
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #FFF;
    border-radius: 20px;

    span {
        font-size: 24px;
        font-weight: 500;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-between;
    }
}
