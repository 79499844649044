@import "src/vars";

.mainStyleButton{
  position: relative;
  padding: 10px 18px 10px 18px;
  font-size: 14px;
  line-height: 19.2px;
  font-weight: 500;
  color: white;
  border-radius: 6px;
  border: none;
  background-color: $deafault-blue;
  cursor: pointer;
  height: 40px;
  width: max-content;
  white-space: nowrap;

  .hoverText {
    position: absolute;
    top: 2px;
    right: 26px;
    display: none;
    font-size: 10px;
    color: $font-gray;
  }

  &:active{
    outline: 2px solid aquamarine;
  }

  &:disabled {
    background-color: #FFF;
    border: 1px solid rgba(149, 149, 149, 1);
    color: rgba(149, 149, 149, 1);
    cursor: default;

    &:active{
      outline: none;
    }
  }

  &:hover {
    .hoverText {
      display: block;
    }
  }
}

.plus {
  position: relative;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
}
