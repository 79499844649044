.wrapperCustomSelect {
  position: relative;

  input {
    padding: 5px 16px 5px 30px;
    background-color: #F9FAFC;
  }

  .icon {
    position: absolute;
    top: 8px;
    left: 10px;

    svg {
      width: 14px;
      height: 14px;

      path {
        fill: #6C6A6A;
      }
    }
  }
}