@import 'src/vars';

.container table {
    margin: 0;
    width: 100%;
    border: none;
    border-collapse: collapse;

    tr {
        border-bottom: 0.5px solid #E9E9E9 !important;
    }

    thead tr {
        border-bottom: none !important;
    }

    td {
        position: relative;
        width: 100%;
        max-width: 100px;
        padding: 0;
        font-size: 14px;
    }

    th {
        height: 30px;
        font-size: 12px;
        color: #00000066;
        text-align: left;
        font-weight: 300;
    }

    td {
        width: min-content;
        div {
            width: 100%;
            padding: 10px 5px 10px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }
    }

    td:nth-child(1) {
        width: 45px;
        padding-right: 20px;
    }

    tbody tr {
        cursor: pointer;

        &:hover {
            background-color: $light-blue;
        }
    }
}