.body {
    padding: 35px 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;

    .buttonContainer {
        position: fixed;
        top: calc(80vh + 65px + 15px);
        right: 30px;

        button {
            background-color: #FF8A8A;
        }
    }

    .inputBlock {
        min-width: 200px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
            caret-color: transparent;
        }
    }

    .tableBlock {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .deleteBtn {
            align-self: flex-end;
            background-color: rgb(210, 36, 36);
            border: 1px solid rgb(210, 36, 36);
        }
    }

    table {
        margin: 0;
        width: 100%;
        border: none;
        border-collapse: collapse;

        tr, td, th, tbody, thead {
            border: 0.5px solid rgba(238, 238, 242, 1) !important
        }

        tbody tr:nth-child(odd) {
            background-color: rgba(247, 248, 249, 1);
        }

        td {
            position: relative;
            padding: 0;
            font-size: 14px;
        }

        th {
            height: 50px;
            font-size: 15px;
            font-weight: 400;
        }

        .first {
            // width: 250px;
        }

        .tdTextName {
            width: 100%;
            padding: 15px 5px 15px 25px;
            box-sizing: border-box;
        }

        .tdText {
            width: 100%;
            padding: 15px 5px;
            box-sizing: border-box;
        }

        .tableTr {
            cursor: pointer;

            &:hover {
                background-color: #D6E9FC;
            }
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(138, 136, 136, 0.5);
    z-index: 2;

    .modal {
        padding: 20px 25px;
        background-color: #FFF;
        border-radius: 30px;

        .title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }

        .btnContainer {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
        }
    }
}

.creationBlock, .fileBlock {

    .inputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .info {
        font-size: 12px;
        margin-bottom: 15px;
    }

    button {
        align-self: flex-end;
    }
}

.fileBlock{
    .inputContainer {
        justify-content: space-between;
    }
}
