@import "src/vars";

.container {
    position: fixed;
    top: 0;
    left: 0;
    // width: 30vw;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    z-index: 2;

    .logoContainer {
        margin-bottom: 8px;
        
        img {
            width: 140px;
        }
    }

    .company {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: $font-gray;
        cursor: pointer;
    }

    .menuItem {
        display: flex;
        margin-top: 5px;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;
        font-size: 16px;
        color: $font-gray;
        cursor: pointer;
    }

    .subMenuItems {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 7px;
        font-size: 14px;
        font-weight: 400;
        color: $font-gray;

        .subMenuItem {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
        
            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background-color: $font-gray;
            }
        }

        .activeSub {
            color: $deafault-blue;
            font-weight: 500;
    
            &::before {
                background-color: $deafault-blue;
            }
        }
    }

    .activeFill {
        position: relative;
        color: $deafault-blue;
        font-weight: 500;

        svg {
            path {
                fill: $deafault-blue;
            }
        }
    }

    .activeStroke {
        position: relative;
        color: $deafault-blue;
        font-weight: 500;

        svg {
            path {
                stroke: $deafault-blue;
                fill: $deafault-blue;
            }
        }
    }

    .openedFolder {
        display: flex;
        padding-left: 10px;
        margin-left: 5px;
        flex-direction: column;
        gap: 3px;
        border-left: 1.5px solid #8B8B8B;
    }
}

.rotate {
    transform: rotate(-90deg);
}
