.selectClients {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 40%;
  position: relative;
}

.wrapperCustomSelect {
  position: relative;
  width: 100%;
}

.customSelect {
  padding: 5px 16px;
  margin: 0 !important;
  border: none;
  outline: none;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #CFCFCF;
  font-size: 12px;
  line-height: 19.2px;
  color: black;
  appearance: none;
  border-radius: 4px;
  width: 100%; 
  background-color: #F9FAFC;
}

.customSelect::placeholder {
  font-size: 12px;
  line-height: 19.2px;
  color: #00000066;
}

.customSelect:disabled {
  background-color: rgba(218, 206, 206, 0.5);
}

.blockOptions {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow-y: scroll;
  z-index: 1;
}

.lable {
  position: absolute;
  top: -15px;
  left: 5px;
  font-size: 12px;
  color: #00000066;
}

.blockOptions::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.blockOptions::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.blockOptions::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #7a8181;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
}
   
.option {
  padding: 2px 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #F1F1FF;
}

.error {
  box-shadow: 0 0 2px 0 rgba(256, 0, 0, 0.8);
}

.arrowSelect {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  // pointer-events: none;
}

.open {
  svg {
    animation: open-button-animate 1s ease alternate;
    transform: rotate(180deg);
  }
}

.close {
  svg {
    animation: close-button-animate 1s ease alternate;
    transform: rotate(0deg);
  }
}


@keyframes open-button-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes close-button-animate {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.disabled {
  cursor: default;
}

.disabled_icon {
  svg path {
    fill: #959595;
  }
}
