@import 'src/vars';

.body {

    .buttonContainer {
        width: 100%;
        padding-bottom: 30px;
        display: flex;
        justify-content: flex-end;
    }

    table {
        margin: 0;
        width: 100%;
        border: none;
        border-collapse: collapse;

        tr {
            border-bottom: 0.5px solid #E9E9E9 !important;
        }

        td {
            position: relative;
            width: 30%;
            max-width: 100px;
            padding: 0;
            font-size: 14px;
            text-align: center;
        }

        th {
            height: 50px;
            font-size: 12px;
            color: #00000066;
            text-align: left;
        }

        .tdTextName {
            width: 100%;
            padding: 10px 5px 10px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }

        .tdText {
            width: 100%;
            padding: 10px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            box-sizing: border-box;
        }

        .tableTr {
            // cursor: pointer;

            &:hover {
                background-color: $light-blue;
            }
        }

        .buttonsTd {
            min-width: 270px;
            max-width: 270px;
        }

        .buttons {
            // width: 260px;
            display: flex;
            justify-content: space-between;
            gap: 5px;
        }
    }
}

.redButton {
    background-color: rgb(210, 36, 36);
    border: 1px solid rgb(210, 36, 36);
}
