.body {
    padding: 5px 10px 10px 10px;
    display: flex;
    gap: 30px;
    justify-content: space-between;


    .inputBlock {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .toogleBlock {
        display: flex;
        gap: 15px;
    }

    .btnContainer {
        position: fixed;
        top: calc(80vh + 65px + 15px);
        left: 250px;
    }
    
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-bottom: -30px;
    }

    .wazzapChannels {
        width: 80vw;
        max-width: 800px;
        // max-width: 400px;
    }

}