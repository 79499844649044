.paginationContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
}

.pagination {
    .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        background-color: rgb(89, 169, 242) !important;
    }
}

.MuiPagination-root {
    button {
      color: red !important;
   }
  }