
@font-face {
    font-family: "Inter"; 
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/Inter_18pt-Light.ttf"); 
}

@font-face {
    font-family: "Inter"; 
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/Inter_18pt-Medium.ttf"); 
}

@font-face {
    font-family: "Inter"; 
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/Inter_18pt-Black.ttf"); 
}


*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Inter;
}


