.container {
    padding-top: 30px;

    .inputContainer {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    textarea {
        font-size: 12px;
    }

    .btnContainer {
        position: fixed;
        top: calc(80vh + 65px + 15px);
        left: 250px;
    }
}