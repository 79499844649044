.container {
    position: relative;
    margin-top: 30px;



    textarea {
        width: 100%;
        height: 150px;
        margin-top: 0;
        padding: 12px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #CFCFCF;
        font-size: 16px;
        font-family: Arial;
        resize: none;
    }
    
    
    textarea:focus {
        border: 1px solid #CFCFCF;
        outline: none;
    }

    textarea:disabled {
      background-color: rgba(218, 206, 206, 0.5) !important;
    }
    
    textarea::-moz-placeholder  {
        color: #00000066;
    }
    
    
    textarea::-webkit-input-placeholder {
        color: #00000066;
    }

    .lable {
        position: absolute;
        top: -30px;
        left: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #000000CC;
      
      }
}

.withoutlable {
  margin-top: 0;
}
