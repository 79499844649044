@import "src/vars";

.nameLabel {
    font-weight: 300;
    font-size: 14px;
    line-height: 16.8px;
  }
  
  .container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
  }
  
  .checkboxNoneStyle {
    display: none;
  }
  
  .checkmark {
    width: 24px;
    height: 24px;
    background-color: white;
    border: 2px solid #CFCFCF;
    border-radius: 50%;
    position: relative;
  
    svg {
      position: absolute;
      width: 11px;
      height: 10px;
      top: 1px;
      left: 1px;
    }
  }
  
  .checkmark:before {
    content: "";
    position: absolute;
    background-color: $deafault-blue;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.2s;
  }
  
  
  .checkboxNoneStyle:checked + .checkmark::before {
    opacity: 1;
  }
  
  .disabled {
    .nameLabel {
      color: #00000099;
    }
    .checkmark:before {
      content: "";
      background-color: #52505099;
    }
  }