@import 'src/vars';

.body {

    .searchContainer {
        position: absolute;
        top: 50px;
        left: 0;
        width: 30%;
        margin-left: calc(30% + 15px);
        margin-top: -30.8px;
        z-index: 2;
    }

    .buttonContainer {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .status {
        color: #00000066;
    }

    .active {
        color: $deafault-blue;
    }

    .editModal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vw;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10;

        & > div {
            position: absolute;
            top: 90px;
            right: 30px;
            width: 400px;
            height: 80vh;
            background-color: #F9FAFC;
            border-radius: 7px;
            overflow-y: scroll;
            scrollbar-width: none ;
            font-size: 12px;
        
            &::-webkit-scrollbar {
                display: none;
            }
    
        }

        .editHeader {
            position: relative;
            background-color: #FFF;
            padding: 15px 10px;
            border-bottom: 1px solid #CFCFCF;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;

            .iconContainer {
                position: absolute;
                top: 15px;
                right: 15px;
                display: flex;
                gap: 10px;
                cursor: pointer;
            }
        }
    }
}