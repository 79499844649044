@import 'src/vars';

.body {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    // justify-content: space-between;

    .searchBlock {
        position: absolute;
        top: 50px;
        left: 0;
        width: 30%;
        margin-left: calc(30% + 15px);
        margin-top: -30.8px;
        z-index: 2;
    }

    .marginLeft {
        margin-left: calc(60% + 30px);
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -70px;
    }

    .rightBlock {
        width: 100%;

        .buttonBlock {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .creationBlock, .fileBlock {
        padding: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 20px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);

        h4 {
            margin-top: 0;
            margin-bottom: 15px;
        }

        .inputContainer {
            width: 100%;
            display: flex;
            gap: 10px;
        }

        .info {
            font-size: 12px;
        }
    }

    .fileBlock{
        .inputContainer {
            justify-content: space-between;
        }
    }

    .tokens {
        margin: 20px 0;
        font-weight: 500;
        color: $font-gray;
    }

    table {

        .action:hover {
            color: rgb(153, 146, 146);
        }

        .status {
            color: #00000066;
        }
    
        .active {
            color: $deafault-blue;
        }
    }
}

.editModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;

    & > div {
        position: absolute;
        top: 90px;
        right: 30px;
        width: 400px;
        height: 80vh;
        background-color: #F9FAFC;
        border-radius: 7px;
        overflow-y: scroll;
        scrollbar-width: none ;
        font-size: 12px;
    
        &::-webkit-scrollbar {
            display: none;
        }

    }

    .editHeader {
        position: relative;
        background-color: #FFF;
        padding: 15px 10px;
        border-bottom: 1px solid #CFCFCF;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;

        .iconContainer {
            position: absolute;
            top: 15px;
            right: 15px;
            display: flex;
            gap: 10px;
            cursor: pointer;
        }
    }
}