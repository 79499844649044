.css-14gyywz-MuiSlider-valueLabel {
    top: -0 !important;
    background-color: transparent !important;
    color: #1718FF !important;
    font-size: 12px !important;
}

.css-14gyywz-MuiSlider-valueLabel::before {
    width: 0 !important;
}

.MuiSlider-thumb:hover {
    box-shadow: none !important;
}

.MuiSlider-thumb.Mui-focusVisible {
    box-shadow: none !important;
}

.MuiSlider-markLabel[data-index="0"] {
    margin-left: 12px !important;
    margin-top: -7px !important;
    color: #000000CC !important;
    font-size: 12px !important;
}

.MuiSlider-markLabel[data-index="1"] {
    margin-left: -12px !important;
    margin-top: -7px !important;
    color: #000000CC !important;
    font-size: 12px !important;
}
