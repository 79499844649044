.hidden {
    display: none;
}

.container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;

    span {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}